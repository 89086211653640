import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Card, TextField, InputAdornment } from "@mui/material";
import * as yup from "yup";
import jwtService from "../../core/auth/services/jwtService";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

const schema = yup.object().shape({
  // username: yup.string().required("You must enter a username"),
  // password: yup
  //   .string()
  //   .required("Please enter your password.")
  //   .min(4, "Password is too short - must be at least 4 chars."),
  sn: yup
    .string()
    .required("You must enter a SN")
});

const defaultValues = {
  username: "",
  password: "",
  remember: true,
};

const Login = (props) => {
  const { submitSn, handleError } = props
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hash = location.hash;
  const hashparam = new URLSearchParams(hash.substring(1));
  const code = hashparam.get("code");
  const snValue = searchParams.get("sn");
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  useEffect(() => {
    setValue("username", "");
    setValue("password", "");
    setValue("sn", snValue || "");

  }, [setValue]);

  function onSubmit({ username, password, sn }) {
    // dispatch(setLodeding(true))
    // dispatch(setLodeding_num(0))
    // console.log(username, password);
    if (!sn) {
      // setError("sn", {
      //   type: "sn",
      //   message: "กรุณาเข้าใช้งานผ่าน QRcode",
      // });
      handleError("กรุณาเข้าใช้งานผ่าน QRcode")
      return;
    }

    jwtService
      .getDevice(sn)
      .then((user) => {
        console.log("user11")
        console.log("sn11")
        console.log(user)
        console.log(sn)
        if (user?.data?.[0]?.sn == sn || user?.device_info?.sn == sn) {
          props.submitSn(user?.data?.[0] || user?.device_info)
        } else {
          handleError("ไม่พบรหัสสาขานี้")
        }

        // dispatch(setLodeding(false))
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((_errors) => {
        handleError("ทำรายการใหม่อีกครั้ง")
      });
  }

  // const handleFirstClick = () => {
  //   navigate("/firstsetup");
  // };

  return (
    <form
      name="loginForm"
      noValidate
      className="flex flex-col justify-center w-full mt-32"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{
          margin: 0,
          textAlign: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            // padding: 2,
            marginTop: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              flexWrap: "wrap",
              padding: 2,
            }}
          >
            <img
              src="../../../../logos/EasyDrinkLogo.png"
              alt="Banner"
              className="w-full h-auto"
              style={{ width: 297, height: 254 }}
            />
          </Box>
          <Box>
            <Controller
              name="sn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  // label="รหัสสาขา"
                  placeholder="รหัสสาขา"
                  fullWidth
                  error={!!errors.sn}
                  helperText={errors ? errors?.sn?.message : ""}
                  disabled={snValue}
                  InputLabelProps={{
                    style: {
                      fontSize: "16px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                      color: "#696868",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "16px",
                      fontFamily: "Kanit, sans-serif",
                      fontWeight: 400,
                      color: "#000000",

                    },
                    width: 368,
                    height: 44,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",

                    },
                    "& .MuiOutlinedInput-input": {
                      textAlign: "center",
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                padding: "12px 24px",
                background: "#006EF9",
                borderRadius: "32px",
                boxShadow: "0px 3px 4px 0px #00000040",
                width: "366px",
                height: "51px",
                "&:hover": {
                  background: "#0099FF",
                },
                fontSize: "22px",
                fontWeight: "bold",
                fontFamily: "Kanit, sans-serif",
                fontWeight: 500,
                color: "#FFFFFF",
                zIndex: 1,
              }}
              type="submit"
            // onClick={handleFirstClick}
            >
              เข้าสู่ระบบ
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: "center",
            backgroundImage: "url('/logos/Wave5.png')",
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "328px",
            marginTop: "auto",
          }}
        />
      </Box>
    </form>
  );
};

export default Login;
