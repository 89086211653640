import React, { useState, useEffect } from "react";
import { Box, Button, Card, TextField, InputAdornment } from "@mui/material";
import { Grid, Typography, IconButton, Paper } from "@mui/material";
import { Delete, Backspace, Close } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtService from "../../core/auth/services/jwtService";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Login from "./Login";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const schema = yup.object().shape({
  // username: yup.string().required("You must enter a username"),
  // password: yup
  //   .string()
  //   .required("Please enter your password.")
  //   .min(4, "Password is too short - must be at least 4 chars."),
  sn: yup.string().required("You must enter a SN"),
});
const defaultValues = {
  username: "",
  password: "",
  remember: true,
};

const LoginPIN = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // const hash = location.hash;
  // const hashparam = new URLSearchParams(hash.substring(1));
  const MySwal = withReactContent(Swal);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);

  // const code = hashparam.get("code");
  const snValue = searchParams.get("sn");
  // console.log(snValue);

  const { control, formState, handleSubmit, setError, setValue, watch } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });
  const { isValid, dirtyFields, errors } = formState;
  useEffect(() => {
    const dd = inputValues?.filter((e) => e);
    if (dd?.length == 6) {
      handleSubmit((data) => onSubmit(data))();
    }
    if (snValue) {
      setValue("sn", snValue || "");
    }
  }, [inputValues]);

  function onSubmit({ sn }) {
    // dispatch(setLodeding(true))
    // dispatch(setLodeding_num(0))
    // console.log(username, password);
    if (!sn) {
      setError("sn", {
        type: "sn",
        message: "กรุณาเข้าใช้งานผ่าน QRcode",
      });
      return;
    }
    jwtService
      .signInWithPIN(inputValues.join(""), sn)
      .then((user) => {
        // dispatch(setLodeding(false))
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((_errors) => {
        // dispatch(setLodeding(false))
        handleError(_errors?.data?.msg || _errors);
        handleClear();
        // _errors.forEach((error) => {
        // setError("username", {
        //   type: "username",
        //   message: "",
        // });
        // setError("password", {
        //   type: "password",
        //   message: _errors || "username/password incorrect",
        // });
        // });
      });
  }

  const handleInputChange = (value) => {
    const nextInputIndex = inputValues.findIndex((val) => val === "");
    if (nextInputIndex !== -1) {
      const newValues = [...inputValues];
      newValues[nextInputIndex] = value;
      setInputValues(newValues);
    }
  };

  const handleClear = () => {
    setInputValues(["", "", "", "", "", ""]);
  };

  const handleBackspace = () => {
    const lastFilledIndex = [...inputValues]
      .reverse()
      .findIndex((val) => val !== "");
    if (lastFilledIndex !== -1) {
      const indexToClear = inputValues.length - 1 - lastFilledIndex;
      const newValues = [...inputValues];
      newValues[indexToClear] = "";
      setInputValues(newValues);
    }
  };

  const handleSubmitSN = (data) => {
    setValue("sn", data?.sn || "");
    setValue("device", data);
  };

  const handleError = (title, message) => {
    MySwal.fire({
      icon: "error",
      title: <strong>{title}</strong>,
      html: <i>{message}</i>,
      showConfirmButton: false,
      timer: 3000,
    });
  };
  const handleSuccess = (msg) => {
    MySwal.fire({
      icon: "success",
      title: <strong>{msg}</strong>,
      showConfirmButton: false,
      timer: 3000,
    });
  };
  const handleGetDevice = (sn) => {
    jwtService
      .getDevice(sn)
      .then((user) => {
        console.log("user22")
        console.log("sn22")
        console.log(user)
        console.log(sn)
        if (user?.data?.[0]?.sn == sn || user?.device_info?.sn == sn) {
          handleSubmitSN(user?.data?.[0] || user?.device_info)
        } else {
          handleError("ไม่พบรหัสสาขานี้")
        }
      })
      .catch((_errors) => {
        handleError("ทำรายการใหม่อีกครั้ง")
      });
  };

  useEffect(() => {
    if (snValue)
      handleGetDevice(snValue)
  }, [snValue]);

  return (
    <>
      {!watch("sn") && (
        <Login
          submitSn={handleSubmitSN}
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      )}
      {watch("sn") && (
        <Box
          sx={{
            margin: 0,
            textAlign: "center",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
              overflow: "hidden",
            }}
          >
            {/* Close Button */}
            {/* <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1000,
          }}
        >
          <Close />
        </IconButton> */}

            {/* Header */}
            <Box
              sx={{
                flex: "3 1 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // bgcolor: "#F6F6F6",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: 2,
                  paddingTop: 4,
                }}
              >
                <Controller
                  name="sn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // label="รหัสสาขา"
                      placeholder="รหัสสาขา"
                      fullWidth
                      error={!!errors.sn}
                      helperText={errors ? errors?.sn?.message : ""}
                      disabled
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#696868",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                          fontWeight: 400,
                          color: "#000000",
                        },
                        width: 368,
                        height: 44,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "15px",
                        },
                        "& .MuiOutlinedInput-input": {
                          textAlign: "center",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: 2,
                  paddingY: 6,
                }}
              >
                {inputValues.map((value, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      backgroundColor: value ? "#006EF9" : "#c9cacb",
                    }}
                  />
                ))}
              </Box>
            </Box>

            {/* Number Pad */}
            <Box
              sx={{
                flex: "6 1 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  width: "80%",
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 2,
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                  <Grid item key={num}>
                    <Button
                      variant="outlined"
                      onClick={() => handleInputChange(num.toString())}
                      sx={{
                        width: 80,
                        height: 80,
                        fontSize: "48px",
                        borderRadius: "56px",
                        color: "#000000",
                      }}
                    >
                      {num}
                    </Button>
                  </Grid>
                ))}

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleClear}
                    sx={{
                      width: 80,
                      height: 80,
                      // fontSize: "48px",
                      borderRadius: "56px",
                      color: "#000000",
                    }}
                  >
                    <Delete sx={{ fontSize: "32px" }} />
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => handleInputChange("0")}
                    sx={{
                      width: 80,
                      height: 80,
                      fontSize: "48px",
                      borderRadius: "56px",
                      color: "#000000",
                    }}
                  >
                    0
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleBackspace}
                    sx={{
                      width: 80,
                      height: 80,
                      // fontSize: "48px",
                      borderRadius: "56px",
                      color: "#000000",
                    }}
                  >
                    <Backspace sx={{ fontSize: "32px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              zIndex: 1,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "323px",
              backgroundImage: "url('../../../../logos/Wave5.png')",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
            }}
          ></Box>
        </Box>
      )}
    </>
  );
};

export default LoginPIN;
