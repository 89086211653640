import { lazy } from "react";
import authRoles from "../core/auth/authRoles";

const FirstSetup = lazy(() => import("./FirstSetup/FirstSetup"));
// const Locations = lazy(() => import("./Location/Locations"));
// const Listofparts = lazy(() => import("./Listofparts/Listofparts"));
// const Manual = lazy(() => import("./Manual/Manual"));
// const SetupChecking = lazy(() => import("./SetupChecking/Setupchecking"));

const Welcome = lazy(() => import("./Welcome/Welcome"));
const Repair = lazy(() => import("./Repairpage/Repair"));
const Supplier = lazy(() => import("./SupplierStocks/Supplier"));
const FinishNegative = lazy(() => import("./SupplierStocks/FinishNegative/FinishNegative"));


const ComponentsConfig = {
  //   auth: authRoles.onlyGuest,
  routes: [
    {
      auth: ['admin', 'ReStock Team', "Service Team"],
      issetup: 1,
      path: "/welcome",
      element: <Welcome />,
    },
    {
      auth: ['admin', 'ReStock Team', "Service Team"],
      issetup: 0,
      path: "/firstsetup",
      element: <FirstSetup />,
    },
    // {
    //   auth: ['admin', 'ReStock Team', "Service Team"],
    //   issetup: 0,
    //   path: "/location",
    //   element: <Locations />,
    // },
    // {
    //   auth: ['admin', 'ReStock Team', "Service Team"],
    //   issetup: 0,
    //   path: "/listofparts",
    //   element: <Listofparts />,
    // },
    // {
    //   auth: ['admin', 'ReStock Team', "Service Team"],
    //   issetup: 0,
    //   path: "/manual",
    //   element: <Manual />,
    // },
    // {
    //   auth: ['admin', 'ReStock Team', "Service Team"],
    //   issetup: 0,
    //   path: "/setupchecking",
    //   element: <SetupChecking />,
    // },
    {
      auth: ["admin", "Service Team"],
      issetup: 1,
      path: '/repair',
      element: <Repair />,
    },
    {
      auth: ['admin', 'ReStock Team'],
      issetup: 1,
      path: '/supplier',
      element: <Supplier />,
    },
    {
      auth: ['admin', 'ReStock Team'],
      issetup: 1,
      path: '/finishsupplier',
      element: <FinishNegative />,
    },
  ],
};

export default ComponentsConfig;
